import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { convertStringToId } from "../../utils";

export default function NetskopeAccordion({ heading, content, reset }) {
  const [htmlContent, setHtmlContent] = useState("");
  const [open, setOpen] = useState(false);
  const accordionRef = useRef(null);

  const addImageEnlargeCode = () => {
    console.log(' =================   addImageEnlargeCode =============')
    const allImages =
      accordionRef.current?.querySelectorAll(".wp-block-image a");
    // console.log("All images:", allImages);
    allImages?.forEach((imageLink) => {
      imageLink.style.cursor = "pointer";
      imageLink.addEventListener("click", (event) => {
        event.preventDefault();
        const image = imageLink.querySelector("img");
        const modal = document.createElement("div");
        modal.style.position = "fixed";
        modal.style.top = 0;
        modal.style.left = 0;
        modal.style.width = "100%";
        modal.style.height = "100%";
        modal.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
        modal.style.display = "flex";
        modal.style.justifyContent = "center";
        modal.style.alignItems = "center";
        modal.style.zIndex = 1000;

        const modalImage = document.createElement("img");
        modalImage.src = image.src;
        modalImage.style.maxWidth = "90%";
        modalImage.style.maxHeight = "90%";
        modalImage.style.borderRadius = "10px";
        modalImage.alt = image.alt || "Image preview";

        // Create close button
        const closeButton = document.createElement("button");
        closeButton.innerText = "×";
        closeButton.style.position = "absolute";
        closeButton.style.top = "10px";
        closeButton.style.right = "20px";
        closeButton.style.background = "none";
        closeButton.style.border = "none";
        closeButton.style.color = "white";
        closeButton.style.fontSize = "30px";
        closeButton.style.cursor = "pointer";

        // Append elements and add close event
        modal.appendChild(modalImage);
        modal.appendChild(closeButton);
        document.body.appendChild(modal);

        closeButton.addEventListener("click", () => {
          modal.remove();
        });

        modal.addEventListener("click", (e) => {
          if (e.target === modal) {
            modal.remove();
          }
        });
      });
    });
  };


  useEffect(() => {
    if (content) {
      if (content instanceof HTMLElement) {
        setHtmlContent(content.outerHTML);
      } else if (typeof content === "string") {
        setHtmlContent(content);
      } else {
        console.error("Invalid content type:", content);
      }
    }
  }, [content]);

    useLayoutEffect(()=>{
      if(htmlContent){
        setTimeout(()=>{
          addImageEnlargeCode()
        },1000)
      }
  },[htmlContent])

  return (
    <div className="border border-gray-300 rounded-lg shadow-md mb-4" ref={accordionRef}>
      {/* Accordion Heading */}
      <div className="bg-gray-100 p-4 flex justify-between items-center cursor-pointer" onClick={() => {
        if(open){
          setOpen(!open)
        }else{
          setOpen(!open)
        }
      }}>
        <h3 className="text-lg font-semibold" id={convertStringToId(heading)}>{heading.replace(/&amp;/g, '&')}</h3>
        {/* <div className="text-lg font-semibold">{heading}</div> */}
        <button className="focus:outline-none">
          {open ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
            </svg>
          )}
        </button>
      </div>

      {/* Accordion Content */}
      <div className={`overflow-hidden transition-all duration-300 ${open ? 'max-h-full' : 'max-h-0'}`}>
        <div className="p-4" dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
    </div>
  );
}
