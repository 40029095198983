import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { BiCaretDown } from "react-icons/bi";

export default function DropdownMenu({ menuItem, title }) {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const location = useLocation();

  const handleLinkClick = () => {
    setIsInputFocused(false);
  };

  const handleBlur = (e) => {
    setTimeout(() => {
      setIsInputFocused(false);
    }, 100);
  };
  
  useEffect(() => {
    setIsInputFocused(false); 
  }, [location]);

  return (
    <div>
      <div className="relative">
        <div className="relative" onBlur={handleBlur}>
          <button
            onClick={() => setIsInputFocused(!isInputFocused)}
            className="flex items-center text-sm"
          >
            {title}
            <span className="text-xl"><BiCaretDown /></span>
            {/* <img src="/icon/down.png" className="mt-[4px]" /> */}
          </button>
          {isInputFocused && (
            <div
              className="w-[160px] my-2 border border-solid border-[#00000040] absolute bg-white text-black top-[25px] z-50 rounded-md p-2"
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              {menuItem.map((item, ix) => (
                <Link
                  key={ix}
                  to={item.link}
                  className="text-gray-700 block hover:text-[#4B9BD8]"
                  onClick={handleLinkClick}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
