import { FaAngleRight } from "react-icons/fa";
import { MdOutlineMenuOpen } from "react-icons/md";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GoDotFill } from "react-icons/go";

import { Icon } from "@netskope-ui/icons";

export function SideBar({ categories }) {
  const [menus, setMenus] = useState({});
  const [toggelMenu, setToggelMenu] = useState(true);
  const [sideMenus, setSideMenus] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleToggleMenu = (id) => {
    const toggle = (items) => {
      return items.map((item) => {
        if (item.link === id) {
          return {
            ...item,
            isChecked: !item.isChecked,
          };
        }
        if (item.children) {
          return {
            ...item,
            children: toggle(item.children),
          };
        }

        return item;
      });
    };

    setSideMenus(toggle(sideMenus));
  };

  const renderMenu = (items) => {
    return items?.map((item, ix) => (
      <div key={ix} className="div_one">
        <div
          className={`flex items-start text-left my-2 ${
            item.active ? "rounded-xl bg-[#FFDF8C]" : ""
          }`}
        >
          {item.children ? (
            <span
              onClick={() => handleToggleMenu(item?.link)}
              className={`cursor-pointer ${
                item.isChecked ? "rotate-90 " : "rotate-0"
              } transition-all duration-500 mt-[4px]`}
            >
              <Icon height={14} name="angle-right" width={14} />
            </span>
          ) : (
            <span className="ml-4"></span>
          )}

          <Link
            to={`/blog/${item?.link}`}
            className="hover:text-[#4B9BD8] text-sm"
          >
            {item.title}
          </Link>
        </div>
        {item.isChecked && item?.children && (
          <div className="border-l-2 border-dashed border-[#eee] pl-2 ml-2">
            {renderSubMenu(item.children)}
          </div>
        )}
      </div>
    ));
  };

  const renderSubMenu = (items) => {
    return items.map((item, ix) => (
      <div key={ix}>
        <div
          className={`flex items-start text-left ${
            item.active ? "rounded-xl bg-[#FFDF8C]" : ""
          }`}
        >
          {item.children ? (
            <span
              onClick={() => handleToggleMenu(item?.link)}
              className={`cursor-pointer ${
                item.isChecked ? "rotate-90 " : "rotate-0"
              } transition-all duration-500 mt-[8px]`}
            >
              <Icon height={14} name="angle-right" width={14} />
            </span>
          ) : (
            <span className="ml-4"></span>
          )}
          <Link
            to={`/blog/${item?.link}`}
            className="text-sm m-[4px] hover:text-[#4B9BD8] text-sm"
          >
            {item.title}
          </Link>
        </div>
        {item.isChecked && item.children && (
          <div className="border-l-2 border-dashed border-[#eee] pl-2 ml-2">
            {renderSubMenu(item.children)}
          </div>
        )}
      </div>
    ));
  };

  function addIsChecked(arr) {
    return arr?.map((item) => ({
      ...item,
      isChecked: false,
      children: addIsChecked(item.children),
    }));
  }

  // ----------------  Skeleton loader -----------------
  const SkeletonBox = () => (
    <div className="skeleton_box my-3">
      {[...Array(7)].map((_, index) => (
        <div
          key={index}
          className={`skeleton h-6 ${index % 2 !== 0 ? "w-[80%]" : ""}`}
        ></div>
      ))}
    </div>
  );

  useEffect(() => {
    if (categories) {
      setLoading(true);
      setMenus(categories[0]);
      setSideMenus(addIsChecked(categories?.children));
      setLoading(false);
    }
  }, [categories]);


  return (
    <aside
      className={`z-50 transition-all duration-500 lg:relative ${toggelMenu ? 'lg:min-w-[300px]':'lg:min-w-[45px]'}  lg:left-[0px] min-w-[60px] h-screen top-0 flex items-start  bg-[#ffffff] absolute ${
        toggelMenu ? "left-[-300px] " : "left-[10px]"
      }`}
    >
      <div
        className={`${toggelMenu ? 'lg:block ':'lg:hidden'} overflow-y-scroll custom_scroll pt-2.5 pr-2.5 pb-2.5 pl-0 w-[300px] border-r-[1px] border-[#cbebf2] h-screen relative `}
      >
        <div className="">
          <div className=" mb-3 border p-2 rounded-lg border-[#88B7C1]">
            <h2 className="text-base font-medium mb-3 text-[#4E88B5]">
              Search Archive
            </h2>
            <div className="flex flex-wrap -mx-2">
              <div className="w-1/2 px-2 mb-3">
                <select className="bg-[#E6E6E6] block w-full py-2 px-3 border border-[#E6E6E6] rounded-md text-[15px] ">
                  <option value="1">Product</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div className="w-1/2 px-2 mb-3">
                <select className="bg-[#E6E6E6] block w-full py-2 px-3 border border-[#E6E6E6] rounded-md text-[15px] ">
                  <option value="1">Version</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div className="w-full px-2">
                <button className="text-[#fff] pt-[6px] pr-[20px] pb-[6px] pl-[20px] rounded-full text-white text-base bg-[#4B9BD8]">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr className="border-t border-[#CBEBF2] w-full my-2" />

        {loading ? (
          <>
            <SkeletonBox />
            <SkeletonBox />
            <SkeletonBox />
          </>
        ) : (
          <>
            <h1 className="flex items-center gap-1 mt-4 font-bold text-base">
              <img src={"/icon/ReleaseNotesIcon1.png"} />
              {menus?.title || "Netskope Client"}
            </h1>
            <div className="mt-2 pl-7 mb-32">{renderMenu(sideMenus)}</div>
          </>
        )}
      </div>

      <button
        className="block z-40  text-[#fff] text-lg bg-[#185E7C] p-2 rounded mt-1 ml-1 lg:opacity-40 lg:hover:opacity-100"
        onClick={() => setToggelMenu(!toggelMenu)}
      >
        <MdOutlineMenuOpen />
      </button>
    </aside>
  );
}
