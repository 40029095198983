import React, { useEffect, useState } from "react";

export default function Test() {
  
  useEffect(() => {
    const sections = document.querySelectorAll("section[id]");

    const navHighlighter = () => {
      let scrollY = window.pageYOffset;
      sections.forEach((current) => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 50;
        const sectionId = current.getAttribute("id");

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          document
            .querySelector(".navigation a[href*=" + sectionId + "]")
            .classList.add("active");
        } else {
          document
            .querySelector(".navigation a[href*=" + sectionId + "]")
            .classList.remove("active");
        }
      });
    };

    window.addEventListener("scroll", navHighlighter);

    return () => {
      window.removeEventListener("scroll", navHighlighter);
    };
  }, []);

  return (
    <div>
      <div className="flex">
        <div className="side-bar bg-gray-800 text-white p-4 w-64">
         
          <nav className={`navigation sticky top-0 h-screen`}>
            <ul>
              <li>
                <a className="block py-2" href="#about">
                  About
                </a>
                <a className="block py-2" href="#products">
                  Products
                </a>
                <a className="block py-2" href="#services">
                  Services
                </a>
                <a className="block py-2" href="#contact">
                  Contact
                </a>
                <a className="block py-2" href="#gallery">
                  Gallery
                </a>
              </li>
            </ul>
          </nav>
          
        </div>
        <div className="main-content flex-grow p-4">
          <section id="about" className="mb-8">
            <h1 className="text-2xl font-bold mb-96">About</h1>
            <p>Your content here</p>
          </section>
          <section id="products" className="mb-8">
            <h1 className="text-2xl font-bold mb-96">Products</h1>
            <p>Your content here</p>
          </section>
          <section id="services" className="mb-8">
            <h1 className="text-2xl font-bold mb-96">Services</h1>
            <p>Your content here</p>
          </section>
          <section id="contact" className="mb-8">
            <h1 className="text-2xl font-bold mb-96">Contact</h1>
            <p>Your content here</p>
          </section>
          <section id="gallery" className="mb-8">
            <h1 className="text-2xl font-bold mb-96">Gallery</h1>
            <p>Your content here</p>
          </section>
        </div>
      </div>
    </div>
  );
}
