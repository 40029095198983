import { Routes, Route, BrowserRouter } from "react-router-dom";
import Test from "./containers/Test";
import BackToTop from "./components/BackToTop";
import Home from "./containers/Home";
import ContentPage from "./containers/ContentPage";
import Layout from "./components/Layout";


function App() {
  return (
    <div className={"main"}>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog/:langCode/:url" element={<ContentPage />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </Layout>
      {/* <BackToTop /> */}
    </div>
  );
}

export default App;
