import { Link } from "react-router-dom";

import DropdownMenu from "./DropdownMenu";

export default function Header() {
  return (
    <header className="bg-primary text-white shadow ">
      <div className="container mx-auto">
        <div className="flex items-center justify-between py-2">
          <div className="" style={{ flex: 1 }}>
            <Link to="/">
              <img src="/images/logo.png" alt="Logo" className="h-8 w-auto" />
            </Link>
          </div>
          <div className="flex items-center gap-4" style={{ flex: 2 }}>
            <nav className="flex space-x-4">
              <DropdownMenu menuItem={dummyMenu} title={"Products"} />
              <DropdownMenu menuItem={dummyMenu} title={"Solutions"} />
            </nav>
            <input
              className="w-full rounded-full p-[6px] mr-12 text-black"
              style={{ flex: 3 }}
            />
          </div>
        </div>
      </div>
    </header>
  );
}

const dummyMenu = [
  {
    id: 1,
    name: "Product solution 1",
    link: "blog/en/security",
  },
  {
    id: 2,
    name: "Product solution 2",
    link: "blog/en/netskope-client-supported-os-and-platform",
  },
];
