import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="container border-t border-t-[#e5e5e5]">
      <div className="flex items-center justify-between p-6">
        <div className="flex items-center gap-3">
          <Link to="https://www.netskope.com/" className="text-gray-700 hover:text-gray-900 text-black">
            Netskope.com
          </Link>
          <Link to="https://support.netskope.com/s/" className="text-gray-700 hover:text-gray-900 text-black">
            Support
          </Link>
          <Link to="https://community.netskope.com/" className="text-gray-700 hover:text-gray-900 text-black">
            Community
          </Link>
        </div>
        <p className="text-sm">© {new Date().getFullYear()} All Rights Reserved. Netskope Inc.</p>
      </div>
    </footer>
  );
}
