
function Home() {
  return (
    <div className="home">
      <h1 className="text-[#015596] font-bold my-2">This is Home page </h1>
      <h1 className="text-[#015596] font-bold my-2">Article Title</h1>
      <h4>Paragraph.</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed
        fringilla nisl, quis sagittis orci. Nulla volutpat pretium convallis.
        Duis eu nulla convallis, posuere dolor id, feugiat est. Integer pulvinar
        quam ac scelerisque fermentum. Fusce rutrum nulla turpis, quis imperdiet
        velit varius quis. Aliquam consectetur dui euismod pretium porta.
        Maecenas rutrum ultricies enim id ornare. Proin id tortor ut odio
        rhoncus varius et et risus. In accumsan molestie massa vel vehicula.
        Mauris rhoncus orci libero, non blandit leo scelerisque in. Integer
        iaculis mauris erat, eu elementum orci cursus a. Fusce quis sagittis
        est. Nulla facilisis leo non purus eleifend, sit amet luctus neque
        finibus.
      </p>
      <h2 className="font-bold my-2">Heading 2</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed
        fringilla nisl, quis sagittis orci. Nulla volutpat pretium convallis.
        Duis eu nulla convallis, posuere dolor id, feugiat est. Integer pulvinar
        quam ac scelerisque fermentum. Fusce rutrum nulla turpis, quis imperdiet
        velit varius quis. Aliquam consectetur dui euismod pretium porta.
        Maecenas rutrum ultricies enim id ornare. Proin id tortor ut odio
        rhoncus varius et et risus. In accumsan molestie massa vel vehicula.
        Mauris rhoncus orci libero, non blandit leo scelerisque in. Integer
        iaculis mauris erat, eu elementum orci cursus a. Fusce quis sagittis
        est. Nulla facilisis leo non purus eleifend, sit amet luctus neque
        finibus.
      </p>

      <h2 className="font-bold my-2">Heading 2</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed
        fringilla nisl, quis sagittis orci. Nulla volutpat pretium convallis.
        Duis eu nulla convallis, posuere dolor id, feugiat est. Integer pulvinar
        quam ac scelerisque fermentum. Fusce rutrum nulla turpis, quis imperdiet
        velit varius quis. Aliquam consectetur dui euismod pretium porta.
        Maecenas rutrum ultricies enim id ornare. Proin id tortor ut odio
        rhoncus varius et et risus. In accumsan molestie massa vel vehicula.
        Mauris rhoncus orci libero, non blandit leo scelerisque in. Integer
        iaculis mauris erat, eu elementum orci cursus a. Fusce quis sagittis
        est. Nulla facilisis leo non purus eleifend, sit amet luctus neque
        finibus.
      </p>

      <h2 className="font-bold my-2">Heading 2</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed
        fringilla nisl, quis sagittis orci. Nulla volutpat pretium convallis.
        Duis eu nulla convallis, posuere dolor id, feugiat est. Integer pulvinar
        quam ac scelerisque fermentum. Fusce rutrum nulla turpis, quis imperdiet
        velit varius quis. Aliquam consectetur dui euismod pretium porta.
        Maecenas rutrum ultricies enim id ornare. Proin id tortor ut odio
        rhoncus varius et et risus. In accumsan molestie massa vel vehicula.
        Mauris rhoncus orci libero, non blandit leo scelerisque in. Integer
        iaculis mauris erat, eu elementum orci cursus a. Fusce quis sagittis
        est. Nulla facilisis leo non purus eleifend, sit amet luctus neque
        finibus.
      </p>

      <h2 className="font-bold my-2">Heading 2</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed
        fringilla nisl, quis sagittis orci. Nulla volutpat pretium convallis.
        Duis eu nulla convallis, posuere dolor id, feugiat est. Integer pulvinar
        quam ac scelerisque fermentum. Fusce rutrum nulla turpis, quis imperdiet
        velit varius quis. Aliquam consectetur dui euismod pretium porta.
        Maecenas rutrum ultricies enim id ornare. Proin id tortor ut odio
        rhoncus varius et et risus. In accumsan molestie massa vel vehicula.
        Mauris rhoncus orci libero, non blandit leo scelerisque in. Integer
        iaculis mauris erat, eu elementum orci cursus a. Fusce quis sagittis
        est. Nulla facilisis leo non purus eleifend, sit amet luctus neque
        finibus.
      </p>

      <h2 className="font-bold my-2">Heading 2</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed
        fringilla nisl, quis sagittis orci. Nulla volutpat pretium convallis.
        Duis eu nulla convallis, posuere dolor id, feugiat est. Integer pulvinar
        quam ac scelerisque fermentum. Fusce rutrum nulla turpis, quis imperdiet
        velit varius quis. Aliquam consectetur dui euismod pretium porta.
        Maecenas rutrum ultricies enim id ornare. Proin id tortor ut odio
        rhoncus varius et et risus. In accumsan molestie massa vel vehicula.
        Mauris rhoncus orci libero, non blandit leo scelerisque in. Integer
        iaculis mauris erat, eu elementum orci cursus a. Fusce quis sagittis
        est. Nulla facilisis leo non purus eleifend, sit amet luctus neque
        finibus.
      </p>

      <h2 className="font-bold my-2">Heading 2</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed
        fringilla nisl, quis sagittis orci. Nulla volutpat pretium convallis.
        Duis eu nulla convallis, posuere dolor id, feugiat est. Integer pulvinar
        quam ac scelerisque fermentum. Fusce rutrum nulla turpis, quis imperdiet
        velit varius quis. Aliquam consectetur dui euismod pretium porta.
        Maecenas rutrum ultricies enim id ornare. Proin id tortor ut odio
        rhoncus varius et et risus. In accumsan molestie massa vel vehicula.
        Mauris rhoncus orci libero, non blandit leo scelerisque in. Integer
        iaculis mauris erat, eu elementum orci cursus a. Fusce quis sagittis
        est. Nulla facilisis leo non purus eleifend, sit amet luctus neque
        finibus.
      </p>

      <div className="flex items-center p-2 bg-[#DFF1FF] rounded-lg border border-[#95D1FF] mt-4 gap-2">
        <div className="rounded-full bg-[#85CAFF] flex items-center justify-center p-2 w-[50px]">
          N
        </div>
        <p>
          Integer pulvinar quam ac scelerisque fermentum. Fusce rutrum nulla
          turpis, quis imperdiet velit varius quis. Aliquam consectetur dui
          euismod pretium porta. Maecenas rutrum ultricies enim id ornare. Proin
          id tortor ut odio rhoncus varius et et risus.
        </p>
      </div>
      <h3 className="font-bold my-2">Heading 3</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed
        fringilla nisl, quis sagittis orci. Nulla volutpat pretium convallis.
        Duis eu nulla convallis, posuere dolor id, feugiat est. Integer pulvinar
        quam ac scelerisque fermentum. Fusce rutrum nulla turpis, quis imperdiet
        velit varius quis. Aliquam consectetur dui euismod pretium porta.
        Maecenas rutrum ultricies enim id ornare. Proin id tortor ut odio
        rhoncus varius et et risus. In accumsan molestie massa vel vehicula.
        Mauris rhoncus orci libero, non blandit leo scelerisque in. Integer
        iaculis mauris erat, eu elementum orci cursus a. Fusce quis sagittis
        est. Nulla facilisis leo non purus eleifend, sit amet luctus neque
        finibus.
      </p>
      <h4 className="font-bold my-2">Heading 4</h4>

      <div className="flex items-center p-2 bg-[#FFFFDF] rounded-lg border border-[#D5D5B5] mt-4 gap-2">
        <div className="rounded-full bg-[#85CAFF] flex items-center justify-center p-2 w-[50px]">
          T
        </div>
        <p>
          Integer pulvinar quam ac scelerisque fermentum. Fusce rutrum nulla
          turpis, quis imperdiet velit varius quis. Aliquam consectetur dui
          euismod pretium porta. Maecenas rutrum ultricies enim id ornare. Proin
          id tortor ut odio rhoncus varius et et risus.
        </p>
      </div>

      <div className="flex items-center p-2 bg-[#FFF0DF] rounded-lg border border-[#95D1FF] mt-4 gap-2">
        <div className="rounded-full bg-[#85CAFF] flex items-center justify-center p-2 w-[50px]">
          W
        </div>
        <p>
          Integer pulvinar quam ac scelerisque fermentum. Fusce rutrum nulla
          turpis, quis imperdiet velit varius quis. Aliquam consectetur dui
          euismod pretium porta. Maecenas rutrum ultricies enim id ornare. Proin
          id tortor ut odio rhoncus varius et et risus.
        </p>
      </div>
      <div className="flex items-center p-2 bg-[#EEFFDF] rounded-lg border border-[#95D1FF] mt-4 gap-2">
        <div className="rounded-full bg-[#85CAFF] flex items-center justify-center p-2 w-[50px]">
          I
        </div>
        <p>
          Integer pulvinar quam ac scelerisque fermentum. Fusce rutrum nulla
          turpis, quis imperdiet velit varius quis. Aliquam consectetur dui
          euismod pretium porta. Maecenas rutrum ultricies enim id ornare. Proin
          id tortor ut odio rhoncus varius et et risus.
        </p>
      </div>
    </div>
  );
}

export default Home;
