import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrumb({ data }) {
  return (
    <div className="bg-[#C4F4FE] rounded-lg p-2 my-4 flex items-center flex-wrap justify-between">
      <div>
        {data?.map((item, ix) => {
          if (ix + 1 < data.length) {
            return (
              <Link
                to={item.link.replace(
                  "https://netskopedocdev.wpengine.com/en/",
                  "/blog/"
                )}
                className={`text-[#0565AE] font-bold text-xs`}
                key={ix}
              >
                {item?.title} {"> "}
              </Link>
            );
          } else {
            return (
              <span className={`text-[#0565AE]  text-xs`} key={ix}>
                {item?.title}
              </span>
            );
          }
        })}
      </div>
      <span className="text-[#0565ae] text-[13px] font-bold">Updated on July 18, 2024</span>
    </div>
  );
}
