import React from "react";
import Header from "./Header";
import { SideBar } from "./SideBar";
import Footer from "./Footer";

export default function Layout({ children }) {
  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex relative gap-2 overflow-hidden container flex-auto">
        {children}
      </div>
      <Footer />
    </div>
  );
}
