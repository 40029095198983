import { useParams } from "react-router-dom";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { fetchData } from "./api";
import { createRoot } from "react-dom/client";
import Breadcrumb from "../../components/Breadcrumb";
import QuickBar from "../../components/Layout/QuickBar";
import { SideBar } from "../../components/Layout/SideBar";
import BackToTop from "../../components/BackToTop";
import FeedbackForm from "../../components/FeedbackForm";
import NetskopeTable from "./NetskopeTable";
// import ToastFactory, {
//   addToast,
//   TOAST_TYPE,
//   TOAST_POSITION,
// } from "@netskope-ui/toast-factory";
import NetskopeAccordion from "./NetskopeAccordion";
import { convertStringToId } from "../../utils";

export default function ContentPage() {
  const [apiData, setApiData] = useState({});
  const [headings, setHeadings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const containerRef = useRef(null);
  const [activeSection, setActiveSection] = useState(null);
  const scrollableRef = useRef(null);
  const { langCode, url } = useParams();

  const fetchDataFunc = async () => {
    setLoading(true);
    const data = await fetchData(langCode, url);
    // Extract the HTML content from the response
    let htmlContent = data?.content || "";
    if (htmlContent) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, "text/html");
      // Extract headings
      const headingTags = doc.querySelectorAll("h1, h2, h3, h4, h5, h6");
      const headingArray = Array.from(headingTags).map((tag) => ({
        tagName: tag.tagName.toLowerCase(),
        text: tag.textContent,
        id: convertStringToId(tag.textContent),
        active: false,
      }));
      setHeadings(headingArray);

      setApiData(data);
    }
    setLoading(false);
  };

  // redirecting a user to a specific section of a page by its ID  ====
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // ------- Skeleton Loader -------
  const SkeletonBox = () => (
    <div className="skeleton_box my-3">
      <div className="skeleton h-9"></div>
      <div className="skeleton h-96"></div>
      {["80%", "60%", "80%", "40%", "60%"].map((width, index) => (
        <div key={index} className={`skeleton h-6 w-[${width}]`}></div>
      ))}
    </div>
  );

  // ====================== code for copied to clipboard ======================
  const copyToClipboard = () => {
    const headingTags = containerRef.current.querySelectorAll(
      "h1, h2, h3, h4, h5, h6"
    );
    headingTags?.forEach((heading) => {
      // console.log('headings =========>',heading.innerText.toLowerCase().replace(/\s+/g,'_'))
      heading.id = convertStringToId(heading.innerText);
      heading.style.cursor = "pointer";
      heading.addEventListener("click", () => {
        const textToCopy = heading.innerText;
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => {
            const popup = document.createElement("div");
            popup.innerText = "Copied to clipboard!";
            popup.style.position = "fixed";
            popup.style.bottom = "20px";
            popup.style.right = "20px";
            popup.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
            popup.style.color = "white";
            popup.style.padding = "10px";
            popup.style.borderRadius = "5px";
            document.body.appendChild(popup);

            setTimeout(() => {
              popup.remove();
            }, 1000);
          })
          .catch((err) => {
            console.error("Failed to copy text: ", err);
          });
      });
    });
  };

  //  ====================== Convert Default Code to Netskope Accordian Box ======================
  const convertAccordianToNetskope = () => {
    console.log(' =================   convertAccordianToNetskope =============')
    const allAccordian = containerRef.current?.querySelectorAll(
      ".wp-block-ub-content-toggle-accordion"
    );
    allAccordian?.forEach((accordian) => {
      let heading = accordian.querySelector(
        ".wp-block-ub-content-toggle-accordion-title"
      ).innerHTML;
      let content = accordian.querySelector(
        ".wp-block-ub-content-toggle-accordion-content-wrap"
      );
      if (heading) {
        const parent = document.createElement("div");
        const root = createRoot(parent);
        root.render(
          
          <NetskopeAccordion
            heading={heading}
            content={content}
            reset={() => {
              addImageEnlargeCode();
            }}
          />





        );
        accordian.replaceWith(parent);
      }
    });
  };

  // ====================== For Handle Image Popup =====================================
  const addImageEnlargeCode = () => {
    console.log(' =================   addImageEnlargeCode =============')
    const allImages =
      containerRef.current?.querySelectorAll(".wp-block-image a");
    // console.log("All images:", allImages);
    allImages?.forEach((imageLink) => {
      imageLink.style.cursor = "pointer";
      imageLink.addEventListener("click", (event) => {
        event.preventDefault();
        const image = imageLink.querySelector("img");
        const modal = document.createElement("div");
        modal.style.position = "fixed";
        modal.style.top = 0;
        modal.style.left = 0;
        modal.style.width = "100%";
        modal.style.height = "100%";
        modal.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
        modal.style.display = "flex";
        modal.style.justifyContent = "center";
        modal.style.alignItems = "center";
        modal.style.zIndex = 1000;

        const modalImage = document.createElement("img");
        modalImage.src = image.src;
        modalImage.style.maxWidth = "90%";
        modalImage.style.maxHeight = "90%";
        modalImage.style.borderRadius = "10px";
        modalImage.alt = image.alt || "Image preview";

        // Create close button
        const closeButton = document.createElement("button");
        closeButton.innerText = "×";
        closeButton.style.position = "absolute";
        closeButton.style.top = "10px";
        closeButton.style.right = "20px";
        closeButton.style.background = "none";
        closeButton.style.border = "none";
        closeButton.style.color = "white";
        closeButton.style.fontSize = "30px";
        closeButton.style.cursor = "pointer";

        // Append elements and add close event
        modal.appendChild(modalImage);
        modal.appendChild(closeButton);
        document.body.appendChild(modal);

        closeButton.addEventListener("click", () => {
          modal.remove();
        });

        modal.addEventListener("click", (e) => {
          if (e.target === modal) {
            modal.remove();
          }
        });
      });
    });
  };

  useEffect(() => {
    if (tableData && apiData?.content) {
      const nodes = containerRef.current.querySelectorAll("table");
      nodes.forEach((table, index) => {
        const parent = document.createElement("div");
        console.log("tableData ====>", tableData);
        const tableInfo = tableData[index];
        if (tableInfo) {
          const { columns, data } = tableInfo;
          const root = createRoot(parent);
          root.render(<NetskopeTable columns={columns} data={data} />);
          table.replaceWith(parent);
        }
      });
    }
   
  }, [apiData, tableData]);

  useEffect(() => {
    if (apiData?.content) {
      // code for Copied to clipboard!
      copyToClipboard();
      addImageEnlargeCode();
      convertAccordianToNetskope();
    }
    if (document) {
      setTimeout(()=>{
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setActiveSection(entry.target.id);
              }
            });
          },
          { threshold: 0.5 } // Adjust this threshold as needed
        );
  
        const headingTags = document.querySelectorAll("h1, h2, h3, h4, h5, h6");
        headingTags.forEach((heading) => observer.observe(heading));
  
        return () => {
          headingTags.forEach((heading) => observer.unobserve(heading));
        };
          },1500)
    }
  }, [apiData]);

  useEffect(() => {
    fetchDataFunc();
  }, [url]);

  // useLayoutEffect(()=>{
  //   setTimeout(()=>{
  //     convertAccordianToNetskope()
  //   },1000)
  // },[apiData,containerRef])

  console.log("========= Content api component ==========");
  return (
    <div className="flex overflow-hidden gap-2 h-full w-full">
      <SideBar categories={apiData?.categories} />
      {!loading ? (
        <div
          className="blog_content home flex-auto overflow-y-scroll custom_scroll px-10 lg:p-0 pb-6"
          id="scrollable-content"
          ref={scrollableRef}
        >
          <BackToTop scrollableRef={scrollableRef} />
          <Breadcrumb data={apiData?.breadcrumbs} />
          <h1 className="text-[#003b49] text-[22px] ">{apiData?.title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: apiData?.content }}
            ref={containerRef}
          />

          <FeedbackForm />
        </div>
      ) : (
        <div className="home flex-auto overflow-y-scroll custom_scroll px-10 lg:px-0">
          {[...Array(2)].map((_, index) => (
            <SkeletonBox key={index} />
          ))}
        </div>
      )}

      <QuickBar
        categories={headings}
        handleRedirecting={(id) => scrollToSection(id)}
        activeSection={activeSection}
      />
    </div>
  );
}
