import { FaAngleRight } from "react-icons/fa";
import { MdOutlineMenuOpen } from "react-icons/md";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { convertStringToId } from "../../utils";

export default function QuickBar({
  categories,
  handleRedirecting,
  activeSection,
}) {
  const [toggelMenu, setToggelMenu] = useState(true);
  const [loading, setLoading] = useState(true);
  const [menuItem, setMenuItem] = useState([]);

  const renderMenu = (items) => {
    return items.map((item, index) => {
      const paddingLeft = `${10 * parseInt(item.tagName[1], 10)}px`;
      return (
        <item.tagName
          className={`cursor-pointer my-[6px] text-sm`}
          style={{ paddingLeft }}
          key={index}
        >
          <span
            onClick={() => {
              handleRedirecting(item.id)
            }}
            className={item.active  ? "text-[#4b9bd8]" : ''}
          >
            {item.text}
          </span>
          {/* <Link to={`#${item.id}`}>  {item.text}</Link> */}
        </item.tagName>
      );
    });
  };

  // ----------------  Skeleton loader -----------------
  const SkeletonBox = () => (
    <div className="skeleton_box my-3">
      {[...Array(7)].map((_, index) => (
        <div
          key={index}
          className={`skeleton h-6 ${index % 2 !== 0 ? "w-[80%]" : ""}`}
        ></div>
      ))}
    </div>
  );

  useEffect(() => {
    setMenuItem(menuItem.map(item => ({
      ...item,
      active: item.id === activeSection
    })));
  }, [activeSection]);
  

  useEffect(() => {
    if (categories) {
      setMenuItem(categories);
    }
  }, [categories]);


  return (
    <aside
      className={`bg-[#ffffff] transition-all duration-1000 lg:relative ${
        toggelMenu ? "lg:min-w-[250px]" : "lg:min-w-[40px]"
      }  lg:min-w-[250px] min-w-[60px] h-screen top-0 flex items-start lg:left-[0px] absolute ${
        toggelMenu ? "right-[-250px]" : "right-[10px]"
      }`}
    >
      <button
        className="block z-40  text-[#fff] text-lg bg-[#185E7C] p-2 rounded mt-1 mr-1 lg:opacity-40 lg:hover:opacity-100"
        onClick={() => setToggelMenu(!toggelMenu)}
      >
        <MdOutlineMenuOpen />
      </button>

      {menuItem ? (
        <div
          className={`${
            toggelMenu ? "lg:block" : "lg:hidden"
          } pt-2.5 pr-0 pb-2.5 pl-2.5 w-[250px]  h-screenrelative border-l-[1px] border-[#cbebf2] border-[#00000053] h-full`}
        >
          {menuItem ? (
            <div className="mt-2 pl-2">{renderMenu(menuItem)}</div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <>
          <SkeletonBox />
          <SkeletonBox />
          <SkeletonBox />
        </>
      )}
    </aside>
  );
}
