import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";

export default function BackToTop({ scrollableRef }) {
  const [showScroll, setShowScroll] = useState(false);

  // const checkScrollTop = () => {
  //   const scrollTop = scrollableRef.current.scrollTop;
  //   if (!showScroll && scrollTop > 200) {
  //     setShowScroll(true);
  //   } else if (showScroll && scrollTop <= 200) {
  //     setShowScroll(false);
  //   }
  // };


  const checkScrollTop = () => {
    if (scrollableRef.current) {
      const scrollTop = scrollableRef.current.scrollTop;
      if (!showScroll && scrollTop > 200) {
        setShowScroll(true);
      } else if (showScroll && scrollTop <= 200) {
        setShowScroll(false);
      }
    }
  };


  const handleScroll = () => {
    scrollableRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const scrollableElement = scrollableRef.current;
    scrollableElement.addEventListener("scroll", checkScrollTop);
    return () => {
      scrollableElement.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScroll, scrollableRef]);

  return (
    <div
      className={
        showScroll
          ? "fixed right-2.5 bottom-2.5 w-auto z-50 bg-[#0c5473] text-white p-3 cursor-pointer rounded-full"
          : "hidden"
      }
      onClick={handleScroll}
    >
      <FaArrowUp />
    </div>
  );
}
