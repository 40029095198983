import React from "react";
import { Table } from "@netskope-ui/table";

export default function NetskopeTable({ columns = [], data = [] }) {
  console.log("Columns: ====>", columns);
  console.log("Data: ===>", data);

  return (
    <div>
      {columns && data && (
        <Table
          columns={columns}
          data={data}
          // data ={ <div dangerouslySetInnerHTML={{ __html: apiData?.content }} />}
          emptyText={<span className="text-sm text-neutral-text">It's empty</span>}
        />
      )}
    </div>
  );
}
