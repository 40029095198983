import React, { useEffect, useState } from "react";
import { Button, Input, Textarea } from "@netskope-ui/core";
export default function FeedbackForm() {
  const [formOpen, setFormOpen] = useState(false);
  useEffect(() => {
    if (formOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [formOpen]);
  return (
    <div className="pt-4">
      <span >
        Feedback / Comment
        <button onClick={() => setFormOpen(true)}  className="text-[#0565ae] ml-2 hover:underline"> How can we help? </button>
      </span>

      {formOpen && (
        <>
          <div className="fixed bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4/5 h-auto max-w-3xl z-50">
            <form
              onSubmit={function noRefCheck() {}}
              style={{
                border: "1px solid black",
                padding: "16px",
              }}
            >
              <Input
                ref={function noRefCheck() {}}
                label="Author"
                className="my-2"
                name="author"
                onBlur={function noRefCheck() {}}
                onChange={function noRefCheck() {}}
                placeholder="Enter Author name"
              />
              <Input
                className="my-2"
                ref={function noRefCheck() {}}
                label="Author"
                name="author"
                type="email"
                onBlur={function noRefCheck() {}}
                onChange={function noRefCheck() {}}
                placeholder="Enter Email"
              />

              <Textarea
                ref={function noRefCheck() {}}
                className="my-2"
                id="feedback"
                name="feedback"
                onBlur={function noRefCheck() {}}
                onChange={function noRefCheck() {}}
                placeholder="Write the feedback ..."
              />
              <Button label="Submit" type="submit" />
            </form>
          </div>
          <div
            className="fixed w-full bg-[#00000029] h-full top-0 left-0 z-40 backdrop-blur-[2px] cursor-pointer"
            onClick={() => setFormOpen(false)}
          ></div>
        </>
      )}
    </div>
  );
}
